.results-padding {
  padding-left: 15px;
  padding-right: 15px;
}
.btn-link {
  color: #636363;
  text-transform: uppercase;
  display: inline-block;
  padding: 10px 20px;
}
.btn-link:hover {
  color: #636363;
}
@media only screen and (max-width: 600px) {
  .btn-full-width {
    display: block;
    width: 100%;
  }
  .override-flex {
    display: block !important;
  }
}
